
.big-title {
  font-size: 16px;
  margin: 30px 0 26px;
}
.blue {
  color: #2461EF;
}
.prompt-info {
  display: flex;
  align-items: center;
  background: #FFFDF0;
  border: 1px solid #FFC8A0;
  padding: 12px 46px;
  line-height: 30px;
  color: #FF9450;
}
.contact-info {
  padding: 10px 38px;
  background: #fafafa;
  border: 1px solid #E5E5E5;
}
.state-info {
  display: flex;
  align-items: center;
  border: 1px solid #E5E5E5;
  padding: 12px 46px;
  line-height: 30px;
  .left {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 30px;
    .iconfont {
      color: #2DC079;
      font-size: 30px;
      margin-right: 20px;
    }
  }
}
